import { get } from 'lodash';
import Axios from 'src/configs/axios';

const USERS_API = {
  getUsersAPI: async (params: Users.ParamsGetList) => {
    const parameters = new URLSearchParams(params as any)
    const response = await Axios.get(
      `/users?${parameters}`,
    );
    const data = get(response, 'data', null);
    return data;
  },

  postUsersAPI: async (params: Users.ParamsPost) => {
    const response: any = await Axios.post('/users', params);
    return response.data;
  },

  updateUsersAPI: async (params: any) => {
    const response: any = await Axios.put(`/users/${params.id}`, params.payload);
    return response.data;
  },

  resetPassWordUsersAPI: async (params: any) => {
    const response: any = await Axios.put(`/users/${params.id}/reset-password`, params.payload);
    return response.status;
  },

  statusUsersAPI: async (params: any) => {
    const response: any = await Axios.put(`/users/${params.id}/status`, params);
    return response.data;
  },
};

export default USERS_API;
