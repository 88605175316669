import { get } from 'lodash';
import Axios from 'src/configs/axios';

const CALENDAR_API = {
  getCalendarAPI: async (params: Calendar.ParamsGetList) => {
    const parameters = new URLSearchParams(params as any)
    const response = await Axios.get(
      `/calendars?${parameters}`,
    );
    const data = get(response, 'data', null);
    return data;
  },

  postCalendarAPI: async (params: Calendar.ParamsPost) => {
    const response: any = await Axios.post('/calendars', params);
    return response.data;
  },

  updateCalendarAPI: async (params: Calendar.ParamsPutDelete) => {
    const response: any = await Axios.put(`/calendars/${params.id}`, params);
    return response.data;
  },

  deleteCalendarAPI: async (params: Calendar.ParamsPutDelete) => {
    const response: any = await Axios.delete(`/calendars/${params.id}`, params);
    return response.data;
  },

};

export default CALENDAR_API;
