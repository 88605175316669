import { get } from 'lodash';
import Axios from 'src/configs/axios';

const CATEGORY_POST_API = {
  getCategoryPost: async () => {
    const response = await Axios.get(
      '/special-event-categories',
    );
    const data = get(response, 'data', null);
    return data;
  },

  postCategoryPost: async (params: CategoryPost.ParamsPost) => {
    const response: any = await Axios.post('/special-event-categories', params);
    return response.data;
  },

  updateCategoryPost: async (params: CategoryPost.ParamsPutDelete) => {
    const response: any = await Axios.put(`/special-event-categories/${params.id}`, params);
    return response.data;
  },

  deleteCategoryPost: async (params: CategoryPost.ParamsPutDelete) => {
    const response: any = await Axios.delete(`/special-event-categories/${params.id}`, params);
    return response.data;
  },

};

export default CATEGORY_POST_API;
