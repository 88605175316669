import { createAsyncThunk } from '@reduxjs/toolkit';

import TAG_API from './tag.api';

export const getTagAction = createAsyncThunk<any, Tag.ParamsGetList>(
  'list_tag',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const data = await TAG_API.getTagAPI(params);
      return data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getAllTagAction = createAsyncThunk<any>(
  'list_all_tag',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const data = await TAG_API.getAllTagAPI();
      return data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const postTagAction = createAsyncThunk<any, Tag.ParamsPost>(
  'post_tag',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await TAG_API.postTagAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);

export const putTagAction = createAsyncThunk<any, Tag.ParamsPutDelete>(
  'put_tag',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await TAG_API.updateTagAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);

export const deleteTagAction = createAsyncThunk<any, Tag.ParamsPutDelete>(
  'delete_tag',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await TAG_API.deleteTagAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);
