import { createAsyncThunk } from '@reduxjs/toolkit';

import CALENDAR_API from './calendar.api';

export const getCalendarAction = createAsyncThunk<any, Calendar.ParamsGetList>(
  'list_calendar',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const data = await CALENDAR_API.getCalendarAPI(params);
      return data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const postCalendarAction = createAsyncThunk<any, any>(
  'post_calendar',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await CALENDAR_API.postCalendarAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);

export const putCalendarAction = createAsyncThunk<any, News.ParamsPutDelete>(
  'put_calendar',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await CALENDAR_API.updateCalendarAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);

export const deleteCalendarAction = createAsyncThunk<any, News.ParamsPutDelete>(
  'delete_calendar',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await CALENDAR_API.deleteCalendarAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);
