import { Layout } from 'antd';
import styled from 'styled-components';

export const PrivateLayoutStyle = styled(Layout)`
  width: 100%;
  height: 100%;
  .content {
    overflow: auto;
  }
`;
