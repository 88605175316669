import React, { Suspense, useMemo } from 'react';
import { Switch } from 'react-router-dom';
import { useAppSelector } from 'src/stores';

import CustomRoute from 'src/components/route/customRoute';
import PrivateRoute from 'src/components/route/privateRoute';
import Loading from 'src/components/loading/Loading';
import { PRIVATE_ROUTES, PUBLIC_ROUTES, CUSTOME_ROUTE } from './constant';
import PublicRoute from 'src/components/route/publicRoute';

const AppRoutes: React.FC = () => {
  const { meInfo } = useAppSelector(state => state.auth);

  const renderPrivateRoute = useMemo(() => {
    if (meInfo) {
      const getRouteByRole = PRIVATE_ROUTES.filter(route => route.role.includes(meInfo?.roles?.[0]));
      return getRouteByRole.map(routeConfig => <PrivateRoute key={routeConfig.path} {...routeConfig} />);
    } else {
      return PRIVATE_ROUTES.map(routeConfig => <PrivateRoute key={routeConfig.path} {...routeConfig} />);
    }
  }, [meInfo]);

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        {renderPrivateRoute}
        {PUBLIC_ROUTES.map(routeConfig => (
          <PublicRoute key={routeConfig.path} {...routeConfig} />
        ))}
        {CUSTOME_ROUTE.map(routeConfig => (
          <CustomRoute key={routeConfig.path} {...routeConfig} />
        ))}
      </Switch>
    </Suspense>
  );
};
export default AppRoutes;
