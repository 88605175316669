import { createSlice } from '@reduxjs/toolkit';
import { deleteCalendarAction, getCalendarAction, postCalendarAction, putCalendarAction } from './calendar.action';

const initialState: Calendar.CalendarState = {
  isLoading: false,
  error: null,
  listCalendar: {
    items: [],
    limit: 10,
    page: 1,
    totalCount: 0,
    totalPage: 0,
  },
};

const { actions, reducer } = createSlice({
  name: 'calendar_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getCalendarAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCalendarAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listCalendar = {
          items: action.payload.records,
          limit: action.payload.pageSize,
          page: action.payload.page,
          totalCount: action.payload.totalRecords,
          totalPage: action.payload.totalPage,
        };
      })
      .addCase(getCalendarAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(postCalendarAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(postCalendarAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(postCalendarAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(putCalendarAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(putCalendarAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(putCalendarAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(deleteCalendarAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteCalendarAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteCalendarAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export { reducer };
export default actions;
