import { get } from 'lodash';
import Axios from 'src/configs/axios';

const POSTS_API = {
  getListPostAPI: async (params: Posts.ParamsGetList) => {
    const query = new URLSearchParams(params as any)
    const response = await Axios.get(
      `/special-events?${query}`,
    );
    const data = get(response, 'data', null);
    return data;
  },

  postPostsAPI: async (params: Posts.ParamsPost) => {
    const response: any = await Axios.post('/special-events', params);
    return response.data;
  },

  updatePostsAPI: async (params: Posts.ParamsPutDelete) => {
    const response: any = await Axios.put(`/special-events/${params.id}`, params);
    return response.data;
  },

  deletePostsAPI: async (params: Posts.ParamsPutDelete) => {
    const response: any = await Axios.delete(`/special-events/${params.id}`, params);
    return response.data;
  },
};

export default POSTS_API;
