import { createSlice } from '@reduxjs/toolkit';
import { deleteContactAction, getContactAction, getContactEmailAction, postContactEmailAction } from './contact.action';

const initialState: Contact.ContactState = {
  isLoading: false,
  error: null,
  listContact: {
    items: [],
    limit: 10,
    page: 1,
    totalCount: 0,
    totalPage: 0,
  },
  listContactEmail: null,
};

const { actions, reducer } = createSlice({
  name: 'contact_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getContactAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getContactAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listContact = {
          items: action.payload.records,
          limit: action.payload.pageSize,
          page: action.payload.page,
          totalCount: action.payload.totalRecords,
          totalPage: action.payload.totalPage,
        };
      })
      .addCase(getContactAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getContactEmailAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getContactEmailAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listContactEmail = action.payload.value;
      })
      .addCase(getContactEmailAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(postContactEmailAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(postContactEmailAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(postContactEmailAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(deleteContactAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteContactAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteContactAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export { reducer };
export default actions;
