import { get } from 'lodash';
import Axios from 'src/configs/axios';

const CATEGORY_API = {
  getCategoryAPI: async (params: Category.ParamsGetList) => {
    const query = new URLSearchParams(params as any)
    const response = await Axios.get(
      `/experiencers?${query}`,
    );
    const data = get(response, 'data', null);
    return data;
  },

  postCategoryAPI: async (params: Category.ParamsPost) => {
    const response: any = await Axios.post('/experiencers', params);
    return response.data;
  },

  updateCategoryAPI: async (params: Category.ParamsPutDelete) => {
    const response: any = await Axios.put(`/experiencers/${params.id}`, params);
    return response.data;
  },

  deleteCategoryAPI: async (params: Category.ParamsPutDelete) => {
    const response: any = await Axios.delete(`/experiencers/${params.id}`, params);
    return response.data;
  },

};

export default CATEGORY_API;
