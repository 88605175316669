import { createSlice } from '@reduxjs/toolkit';
import { deletePostsAction, getListPostAction, postPostsAction, putPostsAction } from './posts.action';

const initialState: Posts.PostsState = {
  isLoading: false,
  error: null,
  listPost: {
    items: [],
    limit: 10,
    page: 1,
    totalCount: 0,
    totalPage: 0,
  },
};

const { actions, reducer } = createSlice({
  name: 'posts_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getListPostAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getListPostAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listPost = {
          items: action.payload.records,
          limit: action.payload.pageSize,
          page: action.payload.page,
          totalCount: action.payload.totalRecords,
          totalPage: action.payload.totalPage,
        };
      })
      .addCase(getListPostAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(postPostsAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(postPostsAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(postPostsAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(putPostsAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(putPostsAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(putPostsAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(deletePostsAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deletePostsAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deletePostsAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export { reducer };
export default actions;
