import { createAsyncThunk } from '@reduxjs/toolkit';

import COMPARE_API from './compare.api';

export const getCompareAction = createAsyncThunk<any>(
  'list_compare',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const data = await COMPARE_API.getCompareAPI();
      return data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const postCompareAction = createAsyncThunk<any, Compare.ParamsPost>(
  'post_compare',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await COMPARE_API.postCompareAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);
