import { get } from 'lodash';
import Axios from 'src/configs/axios';

const CATEGORIES_API = {
  getCategoriesAPI: async (params: Category.ParamsGetList) => {
    const query = new URLSearchParams(params as any)
    const response = await Axios.get(
      `/categories?${query}`,
    );
    const data = get(response, 'data', null);
    return data;
  },

  getParentCategoriesAPI: async () => {
    const response = await Axios.get('/categories/public');
    const data = get(response, 'data', null);
    return data;
  },

  postCategoriesAPI: async (params: Category.ParamsPost) => {
    const response: any = await Axios.post('/categories', params);
    return response.data;
  },

  updateCategoriesAPI: async (params: Category.ParamsPutDelete) => {
    const response: any = await Axios.put(`/categories/${params.id}`, params);
    return response.data;
  },

  deleteCategoriesAPI: async (params: Category.ParamsPutDelete) => {
    const response: any = await Axios.delete(`/categories/${params.id}`, params);
    return response.data;
  },

};

export default CATEGORIES_API;
