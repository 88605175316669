import { combineReducers } from '@reduxjs/toolkit';

import { appReducer } from 'src/stores/app';

import { reducer as authReducer } from './screens/auth/auth.reducer';
import { reducer as usersReducer } from './screens/users/users.reducer';
import { reducer as postsReducer } from './screens/posts/posts.reducer';
import { reducer as categoryPostReducer } from './screens/category-post/categoryPost.reducer';
import { reducer as calendarReducer } from './screens/calendar/calendar.reducer';
import { reducer as newsReducer } from './screens/news/news.reducer';
import { reducer as regionReducer } from './screens/region/region.reducer';
import { reducer as volunteerReducer } from './screens/volunteer/volunteer.reducer';
import { reducer as contactReducer } from './screens/contact/contact.reducer';
import { reducer as categoryReducer } from './screens/category/category.reducer';
import { reducer as categoriesReducer } from './screens/categories/categories.reducer';
import { reducer as tagReducer } from './screens/tag/tag.reducer';
import { reducer as blogsReducer } from './screens/blogs/blogs.reducer';
import { reducer as voiceReducer } from './screens/voice/voice.reducer';
import { reducer as compareReducer } from './screens/compare/compare.reducer';
import { reducer as requirementReducer } from './screens/requirement/requirement.reducer';

export const reducer = combineReducers({
  app: appReducer,
  calendar: calendarReducer,
  categoryPost: categoryPostReducer,
  posts: postsReducer,
  news: newsReducer,
  auth: authReducer,
  region: regionReducer,
  volunteer: volunteerReducer,
  users: usersReducer,
  contact: contactReducer,
  category: categoryReducer,
  voice: voiceReducer,
  categories: categoriesReducer,
  tag: tagReducer,
  blogs: blogsReducer,
  compare: compareReducer,
  requirement: requirementReducer,
});
