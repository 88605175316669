import { createAsyncThunk } from '@reduxjs/toolkit';

import USERS_API from './users.api';

export const getListUserAction = createAsyncThunk<any, Users.ParamsGetList>(
  'list_users',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const data = await USERS_API.getUsersAPI(params);
      return data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const postUsersAction = createAsyncThunk<any, Users.ParamsPost>(
  'post_users',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await USERS_API.postUsersAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);

export const updateUsersAction = createAsyncThunk<any, any>(
  'put_users',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await USERS_API.updateUsersAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);

export const resetPassWordUsersAction = createAsyncThunk<any, any>(
  'reset_pass_users',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await USERS_API.resetPassWordUsersAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);

export const statusUsersAction = createAsyncThunk<any, any>(
  'status_users',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await USERS_API.statusUsersAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);
