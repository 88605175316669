import { get } from 'lodash';
import Axios from 'src/configs/axios';

const CONTACT_API = {
  getContactAPI: async (params: Contact.ParamsGetList) => {
    const query = new URLSearchParams(params as any);
    const response = await Axios.get(`/inquiries?${query}`);
    const data = get(response, 'data', null);
    return data;
  },

  deleteContactAPI: async (params: Contact.ParamsPutDelete) => {
    const response: any = await Axios.delete(`/inquiries/${params.id}`, params);
    return response.data;
  },

  getContactEmailAPI: async () => {
    const response = await Axios.get('/master-data/contact-email');
    const data = get(response, 'data', null);
    return data;
  },

  postContactEmailAPI: async (params: Contact.ParamsContactEmail) => {
    const response: any = await Axios.post('/master-data/contact-email', params);
    return response.data;
  },
};

export default CONTACT_API;
