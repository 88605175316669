import React from 'react';

import { PrivateLayout, PublicLayout, BlankLayout } from 'src/layouts';
import { ADMIN_ROLE } from 'src/constants/auth';

const LoginScreen = React.lazy(
  async () => await import('src/screens/publicScreens').then(module => ({ default: module.LoginScreen })),
);

const ChangePassScreen = React.lazy(
  async () => await import('src/screens/publicScreens').then(module => ({ default: module.ChangePassScreen })),
);

const NotFound = React.lazy(
  async () => await import('src/screens/NotFound').then(module => ({ default: module.NotFound })),
);

const HomeScreen = React.lazy(
  async () => await import('src/screens/privateScreens').then(module => ({ default: module.HomeScreen })),
);

const CalendarScreen = React.lazy(
  async () => await import('src/screens/privateScreens').then(module => ({ default: module.CalendarScreen })),
);

const NewsScreen = React.lazy(
  async () => await import('src/screens/privateScreens').then(module => ({ default: module.NewsScreen })),
);

const CategoryPostsScreen = React.lazy(
  async () => await import('src/screens/privateScreens').then(module => ({ default: module.CategoryPostsScreen })),
);

const PostsScreen = React.lazy(
  async () => await import('src/screens/privateScreens').then(module => ({ default: module.PostsScreen })),
);

const VolunteerScreen = React.lazy(
  async () => await import('src/screens/privateScreens').then(module => ({ default: module.VolunteerScreen })),
);

const RegionScreen = React.lazy(
  async () => await import('src/screens/privateScreens').then(module => ({ default: module.RegionScreen })),
);

const UsersScreen = React.lazy(
  async () => await import('src/screens/privateScreens').then(module => ({ default: module.UsersScreen })),
);

const ContactScreen = React.lazy(
  async () => await import('src/screens/privateScreens').then(module => ({ default: module.ContactScreen })),
);

const CategoryScreen = React.lazy(
  async () => await import('src/screens/privateScreens').then(module => ({ default: module.CategoryScreen })),
);

const VoiceScreen = React.lazy(
  async () => await import('src/screens/privateScreens').then(module => ({ default: module.VoiceScreen })),
);

const Category = React.lazy(
  async () => await import('src/screens/privateScreens').then(module => ({ default: module.Category })),
);

const Tag = React.lazy(
  async () => await import('src/screens/privateScreens').then(module => ({ default: module.Tag })),
);

const BlogScreen = React.lazy(
  async () => await import('src/screens/privateScreens').then(module => ({ default: module.BlogScreen })),
);

const CompareScreen = React.lazy(
  async () => await import('src/screens/privateScreens').then(module => ({ default: module.CompareScreen })),
);

const RequirementScreen = React.lazy(
  async () => await import('src/screens/privateScreens').then(module => ({ default: module.RequirementScreen })),
);

const ProfileScreen = React.lazy(
  async () => await import('src/screens/privateScreens').then(module => ({ default: module.ProfileScreen })),
);

const RoutesName = {
  HOME: '/',
  LOGIN: '/login',
  CHANGE_PASS: '/change-password',
  NOT_FOUND: '*',
  CALENDAR: '/calendar',
  CATEGORY_POSTS: '/categories-posts',
  POSTS: '/posts',
  NEWS: '/news',
  REGION: '/region',
  VOLUNTEER: '/volunteer',
  USERS: '/users',
  CONTACT: '/contact',
  CATEGORY: '/category',
  VOICE: '/voice',
  CATEGORY_BLOGS: '/categories-blogs',
  TAG_BLOGS: '/tag-blogs',
  BLOGS: '/blogs',
  PROFILE: '/profile',
  COMPARE: '/compare',
  REQUIREMENT: '/requirement'
};

export const PRIVATE_ROUTES = [
  {
    path: RoutesName.HOME,
    component: HomeScreen,
    exact: true,
    layout: PrivateLayout,
    role: [ADMIN_ROLE.SUPER_ADMIN, ADMIN_ROLE.ADMIN],
  },
  {
    path: RoutesName.CALENDAR,
    component: CalendarScreen,
    exact: true,
    layout: PrivateLayout,
    role: [ADMIN_ROLE.SUPER_ADMIN, ADMIN_ROLE.ADMIN],
  },
  {
    path: RoutesName.POSTS,
    component: PostsScreen,
    exact: true,
    layout: PrivateLayout,
    role: [ADMIN_ROLE.SUPER_ADMIN, ADMIN_ROLE.ADMIN],
  },
  {
    path: RoutesName.CATEGORY_POSTS,
    component: CategoryPostsScreen,
    exact: true,
    layout: PrivateLayout,
    role: [ADMIN_ROLE.SUPER_ADMIN, ADMIN_ROLE.ADMIN],
  },
  {
    path: RoutesName.NEWS,
    component: NewsScreen,
    exact: true,
    layout: PrivateLayout,
    role: [ADMIN_ROLE.SUPER_ADMIN, ADMIN_ROLE.ADMIN],
  },
  {
    path: RoutesName.REGION,
    component: RegionScreen,
    exact: true,
    layout: PrivateLayout,
    role: [ADMIN_ROLE.SUPER_ADMIN, ADMIN_ROLE.ADMIN],
  },
  {
    path: RoutesName.VOLUNTEER,
    component: VolunteerScreen,
    exact: true,
    layout: PrivateLayout,
    role: [ADMIN_ROLE.SUPER_ADMIN, ADMIN_ROLE.ADMIN],
  },
  {
    path: RoutesName.USERS,
    component: UsersScreen,
    exact: true,
    layout: PrivateLayout,
    role: [ADMIN_ROLE.SUPER_ADMIN],
  },
  {
    path: RoutesName.CONTACT,
    component: ContactScreen,
    exact: true,
    layout: PrivateLayout,
    role: [ADMIN_ROLE.SUPER_ADMIN],
  },
  {
    path: RoutesName.CATEGORY,
    component: CategoryScreen,
    exact: true,
    layout: PrivateLayout,
    role: [ADMIN_ROLE.SUPER_ADMIN, ADMIN_ROLE.ADMIN],
  },
  {
    path: RoutesName.VOICE,
    component: VoiceScreen,
    exact: true,
    layout: PrivateLayout,
    role: [ADMIN_ROLE.SUPER_ADMIN, ADMIN_ROLE.ADMIN],
  },
  {
    path: RoutesName.CATEGORY_BLOGS,
    component: Category,
    exact: true,
    layout: PrivateLayout,
    role: [ADMIN_ROLE.SUPER_ADMIN, ADMIN_ROLE.ADMIN],
  },
  {
    path: RoutesName.TAG_BLOGS,
    component: Tag,
    exact: true,
    layout: PrivateLayout,
    role: [ADMIN_ROLE.SUPER_ADMIN, ADMIN_ROLE.ADMIN],
  },
  {
    path: RoutesName.BLOGS,
    component: BlogScreen,
    exact: true,
    layout: PrivateLayout,
    role: [ADMIN_ROLE.SUPER_ADMIN, ADMIN_ROLE.ADMIN],
  },
  {
    path: RoutesName.PROFILE,
    component: ProfileScreen,
    exact: true,
    layout: PrivateLayout,
    role: [ADMIN_ROLE.SUPER_ADMIN, ADMIN_ROLE.ADMIN],
  },
  {
    path: RoutesName.COMPARE,
    component: CompareScreen,
    exact: true,
    layout: PrivateLayout,
    role: [ADMIN_ROLE.SUPER_ADMIN, ADMIN_ROLE.ADMIN],
  },
  {
    path: RoutesName.REQUIREMENT,
    component: RequirementScreen,
    exact: true,
    layout: PrivateLayout,
    role: [ADMIN_ROLE.SUPER_ADMIN, ADMIN_ROLE.ADMIN],
  },
];

export const PUBLIC_ROUTES = [
  {
    path: RoutesName.LOGIN,
    component: LoginScreen,
    layout: PublicLayout,
  },
  {
    path: RoutesName.CHANGE_PASS,
    component: ChangePassScreen,
    layout: PublicLayout,
  },
];

export const CUSTOME_ROUTE = [
  {
    path: RoutesName.NOT_FOUND,
    component: NotFound,
    newRules: true,
    layout: BlankLayout,
  },
];
