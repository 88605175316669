import { get } from 'lodash';
import Axios from 'src/configs/axios';

const NEWS_API = {
  getNewsAPI: async (params: News.ParamsGetList) => {
    const query = new URLSearchParams(params as any)
    const response = await Axios.get(
      `/news?${query}`,
    );
    const data = get(response, 'data', null);
    return data;
  },

  postNewsAPI: async (params: News.ParamsPost) => {
    const response: any = await Axios.post('/news', params);
    return response.data;
  },

  updateNewsAPI: async (params: News.ParamsPutDelete) => {
    const response: any = await Axios.put(`/news/${params.id}`, params);
    return response.data;
  },

  deleteNewsAPI: async (params: News.ParamsPutDelete) => {
    const response: any = await Axios.delete(`/news/${params.id}`, params);
    return response.data;
  },

};

export default NEWS_API;
