import { get } from 'lodash';
import Axios from 'src/configs/axios';

const COMPARE_API = {
  getCompareAPI: async () => {
    const response = await Axios.get('/master-data/compare');
    const data = get(response, 'data', null);
    return data;
  },

  postCompareAPI: async (params: Compare.ParamsPost) => {
    const response: any = await Axios.post('/master-data/compare', params);
    return response.status;
  },
};

export default COMPARE_API;
