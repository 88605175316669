import { createAsyncThunk } from '@reduxjs/toolkit';

import CATEGORY_POST_API from './categoryPost.api';

export const getCategoryPost = createAsyncThunk<any>(
  'list_categoryPost',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const data = await CATEGORY_POST_API.getCategoryPost();
      return data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const postCategoryPost = createAsyncThunk<any, CategoryPost.ParamsPost>(
  'post_categoryPost',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await CATEGORY_POST_API.postCategoryPost(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);

export const putCategoryPost = createAsyncThunk<any, CategoryPost.ParamsPutDelete>(
  'put_categoryPost',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await CATEGORY_POST_API.updateCategoryPost(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);

export const deleteCategoryPost = createAsyncThunk<any, CategoryPost.ParamsPutDelete>(
  'delete_categoryPost',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await CATEGORY_POST_API.deleteCategoryPost(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);
