import { createAsyncThunk } from '@reduxjs/toolkit';

import NEWS_API from './news.api';

export const getNewsAction = createAsyncThunk<any, News.ParamsGetList>(
  'list_news',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const data = await NEWS_API.getNewsAPI(params);
      return data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const postNewsAction = createAsyncThunk<any, News.ParamsPost>(
  'post_news',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await NEWS_API.postNewsAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);

export const putNewsAction = createAsyncThunk<any, News.ParamsPutDelete>(
  'put_news',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await NEWS_API.updateNewsAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);

export const deleteNewsAction = createAsyncThunk<any, News.ParamsPutDelete>(
  'delete_news',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await NEWS_API.deleteNewsAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);
