import { get } from 'lodash';
import Axios from 'src/configs/axios';

const VOICE_API = {
  getVoiceAPI: async (params: Voice.ParamsGetList) => {
    const query = new URLSearchParams(params as any)
    const response = await Axios.get(
      `/voices?${query}`,
    );
    const data = get(response, 'data', null);
    return data;
  },

  postVoiceAPI: async (params: Voice.ParamsPost) => {
    const response: any = await Axios.post('/voices', params);
    return response.data;
  },

  updateVoiceAPI: async (params: Voice.ParamsPutDelete) => {
    const response: any = await Axios.put(`/voices/${params.id}`, params);
    return response.data;
  },

  deleteVoiceAPI: async (params: Voice.ParamsPutDelete) => {
    const response: any = await Axios.delete(`/voices/${params.id}`, params);
    return response.data;
  },

};

export default VOICE_API;
