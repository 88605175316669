import { createSlice } from '@reduxjs/toolkit';
import { deleteVoiceAction, getVoiceAction, postVoiceAction, putVoiceAction } from './voice.action';

const initialState: Voice.VoiceState = {
  isLoading: false,
  error: null,
  listVoice: {
    items: [],
    limit: 10,
    page: 1,
    totalCount: 0,
    totalPage: 0,
  },
};

const { actions, reducer } = createSlice({
  name: 'voice_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getVoiceAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getVoiceAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listVoice = {
          items: action.payload.records,
          limit: action.payload.pageSize,
          page: action.payload.page,
          totalCount: action.payload.totalRecords,
          totalPage: action.payload.totalPage,
        };
      })
      .addCase(getVoiceAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(postVoiceAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(postVoiceAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(postVoiceAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(putVoiceAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(putVoiceAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(putVoiceAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(deleteVoiceAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteVoiceAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteVoiceAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export { reducer };
export default actions;
