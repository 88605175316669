import { createSlice } from '@reduxjs/toolkit';
import { deleteTagAction, getAllTagAction, getTagAction, postTagAction, putTagAction } from './tag.action';

const initialState: Tag.TagState = {
  isLoading: false,
  error: null,
  listTag: {
    items: [],
    limit: 10,
    page: 1,
    totalCount: 0,
    totalPage: 0,
  },
  listAllTag: [],
};

const { actions, reducer } = createSlice({
  name: 'tag_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getTagAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getTagAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listTag = {
          items: action.payload.records,
          limit: action.payload.pageSize,
          page: action.payload.page,
          totalCount: action.payload.totalRecords,
          totalPage: action.payload.totalPage,
        };
      })
      .addCase(getTagAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getAllTagAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllTagAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listAllTag = action.payload.records;
      })
      .addCase(getAllTagAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(postTagAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(postTagAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(postTagAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(putTagAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(putTagAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(putTagAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(deleteTagAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteTagAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteTagAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export { reducer };
export default actions;
