import React from 'react';
import { MenuProps } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';

import { useAppSelector } from 'src/stores';
import { MENU_ITEM } from './constant';
import { ADMIN_ROLE } from 'src/constants';
import {
  AppstoreAddOutlined,
  CalendarOutlined,
  CopyOutlined,
  FileDoneOutlined,
  GlobalOutlined,
  HddOutlined,
  HeartOutlined,
  HighlightOutlined,
  HomeOutlined,
  MailOutlined,
  ProjectOutlined,
  ReadOutlined,
  SafetyCertificateOutlined,
  SolutionOutlined,
  SwapOutlined,
  TableOutlined,
  TagsOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from '@ant-design/icons';

export interface MenuPropsCustom {
  key: React.Key | string;
  path?: string;
  display: string;
  hidden?: boolean;
}

export type MenuItem = Required<MenuProps & MenuPropsCustom>['items'][number];

export type IGetMenu = () => MenuProps['items'];

export type RecursiveMenu = (
  menus: MenuProps['items'],
  menu: any,
  onFilter: (menu: MenuProps & MenuPropsCustom) => boolean,
) => MenuProps['items'];

export type SelectedTypes = SelectedType[];

export interface SelectedType {
  key: string;
  path: string;
  display: string;
  label?: string;
}

function getItem(
  key: React.Key | string,
  label: React.ReactNode | string,
  display: string,
  path?: string,
  icon?: React.ReactNode,
  hidden?: boolean,
  children?: MenuItem[],
): MenuItem {
  const menuItem = {
    key,
    icon,
    children,
    path,
    label,
    display,
    hidden,
  };
  return menuItem;
}

const getMenus: IGetMenu = () => {
  const meInfo = useAppSelector(state => state.auth.meInfo);

  const adminSuperPaths = [
    getItem(
      MENU_ITEM.DASHBOARD.KEY,
      <NavLink exact to={MENU_ITEM.DASHBOARD.PATH}>
        {MENU_ITEM.DASHBOARD.LABEL}
      </NavLink>,
      MENU_ITEM.DASHBOARD.LABEL,
      MENU_ITEM.DASHBOARD.PATH,
      <HomeOutlined className="icon" />,
      false,
    ),
    getItem(
      MENU_ITEM.CALENDAR.KEY,
      <NavLink exact to={MENU_ITEM.CALENDAR.PATH}>
        {MENU_ITEM.CALENDAR.LABEL}
      </NavLink>,
      MENU_ITEM.CALENDAR.LABEL,
      MENU_ITEM.CALENDAR.PATH,
      <CalendarOutlined className="icon" />,
      false,
    ),
    getItem(
      MENU_ITEM.GROUPS_POSTS.KEY,
      <NavLink exact to={MENU_ITEM.GROUPS_POSTS.PATH}>
        {MENU_ITEM.GROUPS_POSTS.LABEL}
      </NavLink>,
      MENU_ITEM.GROUPS_POSTS.LABEL,
      MENU_ITEM.GROUPS_POSTS.PATH,
      <CopyOutlined className="icon" />,
      false,
      [
        getItem(
          MENU_ITEM.POSTS_CATEGORY.KEY,
          <NavLink exact to={MENU_ITEM.POSTS_CATEGORY.PATH}>
            {MENU_ITEM.POSTS_CATEGORY.LABEL}
          </NavLink>,
          MENU_ITEM.POSTS_CATEGORY.LABEL,
          MENU_ITEM.POSTS_CATEGORY.PATH,
          <HighlightOutlined className="icon" />,
          false,
        ),
        getItem(
          MENU_ITEM.POSTS.KEY,
          <NavLink exact to={MENU_ITEM.POSTS.PATH}>
            {MENU_ITEM.POSTS.LABEL}
          </NavLink>,
          MENU_ITEM.POSTS.LABEL,
          MENU_ITEM.POSTS.PATH,
          <FileDoneOutlined className="icon" />,
          false,
        ),
      ],
    ),
    getItem(
      MENU_ITEM.NEWS.KEY,
      <NavLink exact to={MENU_ITEM.NEWS.PATH}>
        {MENU_ITEM.NEWS.LABEL}
      </NavLink>,
      MENU_ITEM.NEWS.LABEL,
      MENU_ITEM.NEWS.PATH,
      <ReadOutlined className="icon" />,
      false,
    ),
    getItem(
      MENU_ITEM.USERS.KEY,
      <NavLink exact to={MENU_ITEM.USERS.PATH}>
        {MENU_ITEM.USERS.LABEL}
      </NavLink>,
      MENU_ITEM.USERS.LABEL,
      MENU_ITEM.USERS.PATH,
      <UsergroupAddOutlined className="icon" />,
      false,
    ),
    getItem(
      MENU_ITEM.GROUPS_VOLUNTEER.KEY,
      <NavLink exact to={MENU_ITEM.GROUPS_VOLUNTEER.PATH}>
        {MENU_ITEM.GROUPS_VOLUNTEER.LABEL}
      </NavLink>,
      MENU_ITEM.GROUPS_VOLUNTEER.LABEL,
      MENU_ITEM.GROUPS_VOLUNTEER.PATH,
      <SafetyCertificateOutlined className="icon" />,
      false,
      [
        getItem(
          MENU_ITEM.REGION.KEY,
          <NavLink exact to={MENU_ITEM.REGION.PATH}>
            {MENU_ITEM.REGION.LABEL}
          </NavLink>,
          MENU_ITEM.REGION.LABEL,
          MENU_ITEM.REGION.PATH,
          <GlobalOutlined className="icon" />,
          false,
        ),
        getItem(
          MENU_ITEM.VOLUNTEER.KEY,
          <NavLink exact to={MENU_ITEM.VOLUNTEER.PATH}>
            {MENU_ITEM.VOLUNTEER.LABEL}
          </NavLink>,
          MENU_ITEM.VOLUNTEER.LABEL,
          MENU_ITEM.VOLUNTEER.PATH,
          <HeartOutlined className="icon" />,
          false,
        ),
      ],
    ),
    getItem(
      MENU_ITEM.GROUPS_VOICE.KEY,
      <NavLink exact to={MENU_ITEM.GROUPS_VOICE.PATH}>
        {MENU_ITEM.GROUPS_VOICE.LABEL}
      </NavLink>,
      MENU_ITEM.GROUPS_VOICE.LABEL,
      MENU_ITEM.GROUPS_VOICE.PATH,
      <SolutionOutlined className="icon" />,
      false,
      [
        getItem(
          MENU_ITEM.CATEGORY.KEY,
          <NavLink exact to={MENU_ITEM.CATEGORY.PATH}>
            {MENU_ITEM.CATEGORY.LABEL}
          </NavLink>,
          MENU_ITEM.CATEGORY.LABEL,
          MENU_ITEM.CATEGORY.PATH,
          <TableOutlined className="icon" />,
          false,
        ),
        getItem(
          MENU_ITEM.VOICE.KEY,
          <NavLink exact to={MENU_ITEM.VOICE.PATH}>
            {MENU_ITEM.VOICE.LABEL}
          </NavLink>,
          MENU_ITEM.VOICE.LABEL,
          MENU_ITEM.VOICE.PATH,
          <UserOutlined className="icon" />,
          false,
        ),
      ],
    ),
    getItem(
      MENU_ITEM.GROUPS_BLOGS.KEY,
      <NavLink exact to={MENU_ITEM.GROUPS_BLOGS.PATH}>
        {MENU_ITEM.GROUPS_BLOGS.LABEL}
      </NavLink>,
      MENU_ITEM.GROUPS_BLOGS.LABEL,
      MENU_ITEM.GROUPS_BLOGS.PATH,
      <AppstoreAddOutlined className="icon" />,
      false,
      [
        getItem(
          MENU_ITEM.CATEGORY_BLOGS.KEY,
          <NavLink exact to={MENU_ITEM.CATEGORY_BLOGS.PATH}>
            {MENU_ITEM.CATEGORY_BLOGS.LABEL}
          </NavLink>,
          MENU_ITEM.CATEGORY_BLOGS.LABEL,
          MENU_ITEM.CATEGORY_BLOGS.PATH,
          <HddOutlined className="icon" />,
          false,
        ),
        getItem(
          MENU_ITEM.TAG_BLOGS.KEY,
          <NavLink exact to={MENU_ITEM.TAG_BLOGS.PATH}>
            {MENU_ITEM.TAG_BLOGS.LABEL}
          </NavLink>,
          MENU_ITEM.TAG_BLOGS.LABEL,
          MENU_ITEM.TAG_BLOGS.PATH,
          <TagsOutlined className="icon" />,
          false,
        ),
        getItem(
          MENU_ITEM.BLOGS.KEY,
          <NavLink exact to={MENU_ITEM.BLOGS.PATH}>
            {MENU_ITEM.BLOGS.LABEL}
          </NavLink>,
          MENU_ITEM.BLOGS.LABEL,
          MENU_ITEM.BLOGS.PATH,
          <ProjectOutlined className="icon" />,
          false,
        ),
      ],
    ),
    getItem(
      MENU_ITEM.COMPARE.KEY,
      <NavLink exact to={MENU_ITEM.COMPARE.PATH}>
        {MENU_ITEM.COMPARE.LABEL}
      </NavLink>,
      MENU_ITEM.COMPARE.LABEL,
      MENU_ITEM.COMPARE.PATH,
      <SwapOutlined className="icon" />,
      false,
    ),
    getItem(
      MENU_ITEM.REQUIREMENT.KEY,
      <NavLink exact to={MENU_ITEM.REQUIREMENT.PATH}>
        {MENU_ITEM.REQUIREMENT.LABEL}
      </NavLink>,
      MENU_ITEM.REQUIREMENT.LABEL,
      MENU_ITEM.REQUIREMENT.PATH,
      <UnorderedListOutlined className="icon" />,
      false,
    ),
    getItem(
      MENU_ITEM.CONTACT.KEY,
      <NavLink exact to={MENU_ITEM.CONTACT.PATH}>
        {MENU_ITEM.CONTACT.LABEL}
      </NavLink>,
      MENU_ITEM.CONTACT.LABEL,
      MENU_ITEM.CONTACT.PATH,
      <MailOutlined className="icon" />,
      false,
    ),
  ];

  const adminPaths = [
    getItem(
      MENU_ITEM.DASHBOARD.KEY,
      <NavLink exact to={MENU_ITEM.DASHBOARD.PATH}>
        {MENU_ITEM.DASHBOARD.LABEL}
      </NavLink>,
      MENU_ITEM.DASHBOARD.LABEL,
      MENU_ITEM.DASHBOARD.PATH,
      <HomeOutlined className="icon" />,
      false,
    ),
    getItem(
      MENU_ITEM.CALENDAR.KEY,
      <NavLink exact to={MENU_ITEM.CALENDAR.PATH}>
        {MENU_ITEM.CALENDAR.LABEL}
      </NavLink>,
      MENU_ITEM.CALENDAR.LABEL,
      MENU_ITEM.CALENDAR.PATH,
      <CalendarOutlined className="icon" />,
      false,
    ),
    getItem(
      MENU_ITEM.POSTS.KEY,
      <NavLink exact to={MENU_ITEM.POSTS.PATH}>
        {MENU_ITEM.POSTS.LABEL}
      </NavLink>,
      MENU_ITEM.POSTS.LABEL,
      MENU_ITEM.POSTS.PATH,
      <FileDoneOutlined className="icon" />,
      false,
    ),
    getItem(
      MENU_ITEM.NEWS.KEY,
      <NavLink exact to={MENU_ITEM.NEWS.PATH}>
        {MENU_ITEM.NEWS.LABEL}
      </NavLink>,
      MENU_ITEM.NEWS.LABEL,
      MENU_ITEM.NEWS.PATH,
      <ReadOutlined className="icon" />,
      false,
    ),
    getItem(
      MENU_ITEM.GROUPS_VOLUNTEER.KEY,
      <NavLink exact to={MENU_ITEM.GROUPS_VOLUNTEER.PATH}>
        {MENU_ITEM.GROUPS_VOLUNTEER.LABEL}
      </NavLink>,
      MENU_ITEM.GROUPS_VOLUNTEER.LABEL,
      MENU_ITEM.GROUPS_VOLUNTEER.PATH,
      <SafetyCertificateOutlined className="icon" />,
      false,
      [
        getItem(
          MENU_ITEM.REGION.KEY,
          <NavLink exact to={MENU_ITEM.REGION.PATH}>
            {MENU_ITEM.REGION.LABEL}
          </NavLink>,
          MENU_ITEM.REGION.LABEL,
          MENU_ITEM.REGION.PATH,
          <GlobalOutlined className="icon" />,
          false,
        ),
        getItem(
          MENU_ITEM.VOLUNTEER.KEY,
          <NavLink exact to={MENU_ITEM.VOLUNTEER.PATH}>
            {MENU_ITEM.VOLUNTEER.LABEL}
          </NavLink>,
          MENU_ITEM.VOLUNTEER.LABEL,
          MENU_ITEM.VOLUNTEER.PATH,
          <HeartOutlined className="icon" />,
          false,
        ),
      ],
    ),
    getItem(
      MENU_ITEM.GROUPS_VOICE.KEY,
      <NavLink exact to={MENU_ITEM.GROUPS_VOICE.PATH}>
        {MENU_ITEM.GROUPS_VOICE.LABEL}
      </NavLink>,
      MENU_ITEM.GROUPS_VOICE.LABEL,
      MENU_ITEM.GROUPS_VOICE.PATH,
      null,
      false,
      [
        getItem(
          MENU_ITEM.CATEGORY.KEY,
          <NavLink exact to={MENU_ITEM.CATEGORY.PATH}>
            {MENU_ITEM.CATEGORY.LABEL}
          </NavLink>,
          MENU_ITEM.CATEGORY.LABEL,
          MENU_ITEM.CATEGORY.PATH,
          <TableOutlined className="icon" />,
          false,
        ),
        getItem(
          MENU_ITEM.VOICE.KEY,
          <NavLink exact to={MENU_ITEM.VOICE.PATH}>
            {MENU_ITEM.VOICE.LABEL}
          </NavLink>,
          MENU_ITEM.VOICE.LABEL,
          MENU_ITEM.VOICE.PATH,
          <UserOutlined className="icon" />,
          false,
        ),
      ],
    ),
    getItem(
      MENU_ITEM.GROUPS_BLOGS.KEY,
      <NavLink exact to={MENU_ITEM.GROUPS_BLOGS.PATH}>
        {MENU_ITEM.GROUPS_BLOGS.LABEL}
      </NavLink>,
      MENU_ITEM.GROUPS_BLOGS.LABEL,
      MENU_ITEM.GROUPS_BLOGS.PATH,
      <AppstoreAddOutlined className="icon" />,
      false,
      [
        getItem(
          MENU_ITEM.CATEGORY_BLOGS.KEY,
          <NavLink exact to={MENU_ITEM.CATEGORY_BLOGS.PATH}>
            {MENU_ITEM.CATEGORY_BLOGS.LABEL}
          </NavLink>,
          MENU_ITEM.CATEGORY_BLOGS.LABEL,
          MENU_ITEM.CATEGORY_BLOGS.PATH,
          <HddOutlined className="icon" />,
          false,
        ),
        getItem(
          MENU_ITEM.TAG_BLOGS.KEY,
          <NavLink exact to={MENU_ITEM.TAG_BLOGS.PATH}>
            {MENU_ITEM.TAG_BLOGS.LABEL}
          </NavLink>,
          MENU_ITEM.TAG_BLOGS.LABEL,
          MENU_ITEM.TAG_BLOGS.PATH,
          <TagsOutlined className="icon" />,
          false,
        ),
        getItem(
          MENU_ITEM.BLOGS.KEY,
          <NavLink exact to={MENU_ITEM.BLOGS.PATH}>
            {MENU_ITEM.BLOGS.LABEL}
          </NavLink>,
          MENU_ITEM.BLOGS.LABEL,
          MENU_ITEM.BLOGS.PATH,
          <ProjectOutlined className="icon" />,
          false,
        ),
      ],
    ),
    getItem(
      MENU_ITEM.COMPARE.KEY,
      <NavLink exact to={MENU_ITEM.COMPARE.PATH}>
        {MENU_ITEM.COMPARE.LABEL}
      </NavLink>,
      MENU_ITEM.COMPARE.LABEL,
      MENU_ITEM.COMPARE.PATH,
      <SwapOutlined className="icon" />,
      false,
    ),
    getItem(
      MENU_ITEM.REQUIREMENT.KEY,
      <NavLink exact to={MENU_ITEM.REQUIREMENT.PATH}>
        {MENU_ITEM.REQUIREMENT.LABEL}
      </NavLink>,
      MENU_ITEM.REQUIREMENT.LABEL,
      MENU_ITEM.REQUIREMENT.PATH,
      <UnorderedListOutlined className="icon" />,
      false,
    ),
  ];

  if (meInfo) {
    switch (meInfo?.roles?.[0]) {
      case ADMIN_ROLE.SUPER_ADMIN:
        return adminSuperPaths;
      case ADMIN_ROLE.ADMIN:
        return adminPaths;
      default:
        return [];
    }
  } else {
    return [];
  }
};

const useSideBarHook = (): {
  menuDisplay: MenuProps['items'];
  menuSelected: MenuProps['items'];
  getDisplayMenu: (menus: MenuProps['items']) => MenuProps['items'];
  getSelectedMenu: (menus: MenuProps['items']) => SelectedTypes;
} => {
  const { pathname } = useLocation();

  const onGetShowedMenu: RecursiveMenu = (menus, menu, onFilter) => {
    menu.children = (menu.children ?? []).reduce((a, i) => onGetShowedMenu(a, i, onFilter), []);
    if (!menu.children.length) delete menu.children;

    if (onFilter(menu) || menu.children) {
      menus?.push(menu);
    }

    return menus;
  };

  const getDisplayMenu = (listMenu): MenuProps['items'] => {
    return listMenu?.reduce((a, i) => onGetShowedMenu(a, i, x => !x?.hidden), []);
  };

  const selectedMenu: RecursiveMenu = (menus, menu, onFilter) => {
    menu.children = (menu.children || []).reduce((a, i) => selectedMenu(a, i, onFilter), []);
    if (!menu.children.length) delete menu.children;

    if ((onFilter(menu) || menu.children) && menus) {
      menus.push(menu);
    }

    return menus;
  };

  const getSelectedMenu = (menus): SelectedTypes => {
    const selectedMenus: MenuProps['items'] = menus.reduce(
      (a, i) =>
        selectedMenu(a, i, x =>
          pathname !== '/'
            ? x?.key !== '/' && pathname.includes(x?.key as string)
            : pathname.includes(x?.key as string),
        ),
      [],
    );

    const getKeyArray = (menus): SelectedTypes => {
      const keys: SelectedTypes = [];

      menus.forEach(item => {
        if (item?.key) {
          keys.push({
            key: item?.key ?? '',
            path: item?.path ?? '',
            display: item?.display ?? '',
            label: item?.label ?? '',
          });

          if (item?.children?.length) {
            keys.push(...getKeyArray(item?.children));
          }
        }
      });

      return keys;
    };

    const getKeySelected: SelectedTypes = getKeyArray(selectedMenus);

    return getKeySelected;
  };

  return {
    menuDisplay: getMenus(),
    menuSelected: getMenus(),
    getDisplayMenu,
    getSelectedMenu,
  };
};

export default useSideBarHook;
