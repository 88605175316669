import { createSlice } from '@reduxjs/toolkit';
import { getCompareAction, postCompareAction } from './compare.action';

const initialState: Compare.CompareState = {
  isLoading: false,
  error: null,
  listCompare: {
    initialCost: {
      tripTicket: '',
      liveExpenses: '',
      total: '',
    },
    incomeBenefit: {
      income: '',
      paidHoliday: '',
      tuitionAssistance: '',
    },
    learningEnvironment: '',
  },
};

const { actions, reducer } = createSlice({
  name: 'compare_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getCompareAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCompareAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listCompare = action.payload.value;
      })
      .addCase(getCompareAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(postCompareAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(postCompareAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(postCompareAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export { reducer };
export default actions;
