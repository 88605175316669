import { get } from 'lodash';
import Axios from 'src/configs/axios';

const TAG_API = {
  getTagAPI: async (params: Tag.ParamsGetList) => {
    const query = new URLSearchParams(params as any)
    const response = await Axios.get(
      `/tags?${query}`,
    );
    const data = get(response, 'data', null);
    return data;
  },

  getAllTagAPI: async () => {
    const response = await Axios.get('/tags/public');
    const data = get(response, 'data', null);
    return data;
  },

  postTagAPI: async (params: Tag.ParamsPost) => {
    const response: any = await Axios.post('/tags', params);
    return response.data;
  },

  updateTagAPI: async (params: Tag.ParamsPutDelete) => {
    const response: any = await Axios.put(`/tags/${params.id}`, params);
    return response.data;
  },

  deleteTagAPI: async (params: Tag.ParamsPutDelete) => {
    const response: any = await Axios.delete(`/tags/${params.id}`, params);
    return response.data;
  },

};

export default TAG_API;
