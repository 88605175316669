import { get } from 'lodash';
import Axios from 'src/configs/axios';

const BLOGS_API = {
  getBlogsAPI: async (params: Blogs.ParamsGetList) => {
    const query = new URLSearchParams(params as any)
    const response = await Axios.get(
      `/blogs?${query}`,
    );
    const data = get(response, 'data', null);
    return data;
  },

  postBlogsAPI: async (params: Blogs.ParamsPost) => {
    const response: any = await Axios.post('/blogs', params);
    return response.data;
  },

  updateBlogsAPI: async (params: Blogs.ParamsPutDelete) => {
    const response: any = await Axios.put(`/blogs/${params.id}`, params);
    return response.data;
  },

  deleteBlogsAPI: async (params: Blogs.ParamsPutDelete) => {
    const response: any = await Axios.delete(`/blogs/${params.id}`, params);
    return response.data;
  },

};

export default BLOGS_API;
