import { createAsyncThunk } from '@reduxjs/toolkit';

import VOLUNTEER_API from './volunteer.api';

export const getVolunteerAction = createAsyncThunk<any, Volunteer.ParamsGetList>(
  'list_volunteer',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const data = await VOLUNTEER_API.getVolunteerAPI(params);
      return data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const postVolunteerAction = createAsyncThunk<any, Volunteer.ParamsPost>(
  'post_volunteer',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await VOLUNTEER_API.postVolunteerAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);

export const putVolunteerAction = createAsyncThunk<any, Volunteer.ParamsPutDelete>(
  'put_volunteer',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await VOLUNTEER_API.updateVolunteerAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);

export const deleteVolunteerAction = createAsyncThunk<any, Volunteer.ParamsPutDelete>(
  'delete_volunteer',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await VOLUNTEER_API.deleteVolunteerAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);
