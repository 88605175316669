/* eslint-disable n/no-callback-literal */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom';

import AUTH_API from './auth.api';

export const loginAction = createAsyncThunk<any, Auth.LoginRequestPayload>(
  'auth',
  async ({ data }, { dispatch, rejectWithValue }) => {
    try {
      const user = await AUTH_API.loginAPI(data);
      return user;
    } catch (err: any) {
      return rejectWithValue(err?.message || err?.name);
    }
  },
);

export const getMeAction = createAsyncThunk<any>('getMeAction', async (_, { dispatch, rejectWithValue }) => {
  try {
    const data = await AUTH_API.getMeAPI();

    return data;
  } catch (err: any) {
    const history = useHistory();
    history.push('/login');
    return rejectWithValue(err);
  }
});

export const changePasswordAction = createAsyncThunk<any, any>(
  'change_pass',
  async ({ data, callback = (status?: boolean) => {} }, { dispatch, rejectWithValue }) => {
    try {
      const user = await AUTH_API.changePasswordAPI(data);
      callback(true);
      return user;
    } catch (err: any) {
      callback(false);
      rejectWithValue(err?.message || err?.name);
    }
  },
);
