import { createAsyncThunk } from '@reduxjs/toolkit';

import VOICE_API from './voice.api';

export const getVoiceAction = createAsyncThunk<any, Voice.ParamsGetList>(
  'list_voice',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const data = await VOICE_API.getVoiceAPI(params);
      return data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const postVoiceAction = createAsyncThunk<any, Voice.ParamsPost>(
  'post_voice',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await VOICE_API.postVoiceAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);

export const putVoiceAction = createAsyncThunk<any, Voice.ParamsPutDelete>(
  'put_voice',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await VOICE_API.updateVoiceAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);

export const deleteVoiceAction = createAsyncThunk<any, Voice.ParamsPutDelete>(
  'delete_voice',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await VOICE_API.deleteVoiceAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);
