import { createSlice } from '@reduxjs/toolkit';
import { deleteVolunteerAction, getVolunteerAction, postVolunteerAction, putVolunteerAction } from './volunteer.action';

const initialState: Volunteer.VolunteerState = {
  isLoading: false,
  error: null,
  listVolunteer: {
    items: [],
    limit: 10,
    page: 1,
    totalCount: 0,
    totalPage: 0,
  },
};

const { actions, reducer } = createSlice({
  name: 'volunteer_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getVolunteerAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getVolunteerAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listVolunteer = {
          items: action.payload.records,
          limit: action.payload.pageSize,
          page: action.payload.page,
          totalCount: action.payload.totalRecords,
          totalPage: action.payload.totalPage,
        };
      })
      .addCase(getVolunteerAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(postVolunteerAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(postVolunteerAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(postVolunteerAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(putVolunteerAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(putVolunteerAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(putVolunteerAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(deleteVolunteerAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteVolunteerAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteVolunteerAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export { reducer };
export default actions;
