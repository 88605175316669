import { createSlice } from '@reduxjs/toolkit';
import { deleteNewsAction, getNewsAction, postNewsAction, putNewsAction } from './news.action';

const initialState: News.NewsState = {
  isLoading: false,
  error: null,
  listNews: {
    items: [],
    limit: 10,
    page: 1,
    totalCount: 0,
    totalPage: 0,
  },
};

const { actions, reducer } = createSlice({
  name: 'news_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getNewsAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getNewsAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listNews = {
          items: action.payload.records,
          limit: action.payload.pageSize,
          page: action.payload.page,
          totalCount: action.payload.totalRecords,
          totalPage: action.payload.totalPage,
        };
      })
      .addCase(getNewsAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(postNewsAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(postNewsAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(postNewsAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(putNewsAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(putNewsAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(putNewsAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(deleteNewsAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteNewsAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteNewsAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export { reducer };
export default actions;
