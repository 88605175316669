import React from 'react';
import { Layout } from 'antd';

import { PublicLayoutStyle } from './PublicLayout.style';

const { Content } = Layout;

const PublicLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <PublicLayoutStyle>
      <Layout className="layout-body">
        <Content className="body-content">{children}</Content>
      </Layout>
    </PublicLayoutStyle>
  );
};

export default PublicLayout;
