export const FORMAT_TIME = {
  YEAR_MONTH_DATE: 'YYYY/MM/DD',
  DATE_HOUR_MINUTES: 'YYYY/MM/DD HH:mm',
  DATE_HOUR_MINUTES_SECOND: 'YYYY-MM-DD HH:mm:ss',
  HOUR_MINUTES: 'HH:mm',
  YEAR_MONTH_DATE_LOWER: 'YYYY-MM-DD',
  HOUR_MINUES_LOWER: 'HH:mm',
  HOUR_MINUES_SECOND: 'HH:mm:ss',
  MINUES_SECOND_LOWER: 'mm:ss',
  FULL_DATE: 'YYYY/MM/DD HH:mm',
  DATE_HOUR_MINUTES_DAY_OF_WEEK: 'YYYY/MM/DD (dddd) HH:mm',
};
