import { createSlice } from '@reduxjs/toolkit';
import { deleteCategoryAction, getCategoryAction, postCategoryAction, putCategoryAction } from './category.action';

const initialState: Category.CategoryState = {
  isLoading: false,
  error: null,
  listCategory: {
    items: [],
    limit: 10,
    page: 1,
    totalCount: 0,
    totalPage: 0,
  },
};

const { actions, reducer } = createSlice({
  name: 'category_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getCategoryAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCategoryAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listCategory = {
          items: action.payload.records,
          limit: action.payload.pageSize,
          page: action.payload.page,
          totalCount: action.payload.totalRecords,
          totalPage: action.payload.totalPage,
        };
      })
      .addCase(getCategoryAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(postCategoryAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(postCategoryAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(postCategoryAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(putCategoryAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(putCategoryAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(putCategoryAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(deleteCategoryAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteCategoryAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteCategoryAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export { reducer };
export default actions;
