import styled from 'styled-components';
import { Layout } from 'antd';

const { Sider } = Layout;

export const SideBarCardStyled = styled(Sider)`
  color: #ffffff;

  &.ant-layout-sider-collapsed {
    .ant-layout-sider-zero-width-trigger {
      transform: rotate(180deg);
    }
  }

  &.layout-sider {
    background-color: #191c24;

    .ant-layout-sider-children {
    }
  }

  .layout-menu {
    border-right: none;
    background-color: transparent;
    padding: 8px 13px;

    .ant-menu-item {
      height: 46px;

      &::after {
        border: unset;
      }

      &:active {
        background-color: #2c2e36;
      }

      a {
        color: #ffffff;
        font-size: 16px;
      }

      .ant-menu-title-content {
        padding-left: 10px;
      }

      .anticon {
        color: #ffffff;
        font-size: 24px;
      }
    }

    .ant-menu-submenu {
      .ant-menu-title-content {
        padding-left: 10px;
      }
    }

    .ant-menu-submenu-title {
      .ant-menu-title-content {
        a {
          color: #ffffff;
          font-size: 16px;
        }
      }

      .anticon {
        color: #ffffff;
        font-size: 24px;
      }

      .ant-menu-submenu-arrow {
        color: #ffffff;
      }
    }

    .ant-menu-item-selected {
      border-radius: 5px;
      color: black;

      .ant-menu-title-content a {
        color: black;
      }

      .anticon {
        color: black;
      }
    }

    .ant-menu-submenu-selected {
      .ant-menu-submenu-title {
        color: #ffffff;
        border-radius: 5px;

        .anticon {
          color: #ffffff;
          font-size: 24px;
        }
      }

      svg {
        path {
          stroke: #ffffff;
        }
      }

      .ant-menu-submenu-arrow {
        color: #ffffff;
      }
    }

    .ant-menu-inline {
      background-color: #2c2e36;
    }
  }

  .logo-header {
    filter: invert();
    padding: 12px;
  }
`;
