import { createSlice } from '@reduxjs/toolkit';
import { deleteRegionAction, getRegionAction, postRegionAction, putRegionAction } from './region.action';

const initialState: Region.RegionState = {
  isLoading: false,
  error: null,
  listRegion: [],
};

const { actions, reducer } = createSlice({
  name: 'region_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getRegionAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getRegionAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listRegion = action.payload.records;
      })
      .addCase(getRegionAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(postRegionAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(postRegionAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(postRegionAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(putRegionAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(putRegionAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(putRegionAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(deleteRegionAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteRegionAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteRegionAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export { reducer };
export default actions;
