export const MENU_ITEM = {
  DASHBOARD: {
    PATH: '/',
    LABEL: 'ダッシュボード',
    KEY: '/',
    TYPE: true,
  },

  CALENDAR: {
    PATH: '/calendar',
    LABEL: '説明会',
    KEY: '/calendar',
    TYPE: true,
  },
  GROUPS_POSTS: {
    PATH: '/posts',
    LABEL: 'イベント',
    KEY: '/posts-groups',
    TYPE: true,
  },
  POSTS_CATEGORY: {
    PATH: '/categories-posts',
    LABEL: 'カテゴリー',
    KEY: '/categories-posts',
    TYPE: true,
  },
  POSTS: {
    PATH: '/posts',
    LABEL: 'イベント',
    KEY: '/posts',
    TYPE: true,
  },
  NEWS: {
    PATH: '/news',
    LABEL: 'TOPニュース',
    KEY: '/news',
    TYPE: true,
  },
  USERS: {
    PATH: '/users',
    LABEL: 'ユーザー',
    KEY: '/users',
    TYPE: true,
  },
  GROUPS_VOLUNTEER: {
    PATH: '/volunteer',
    LABEL: 'ボランティア',
    KEY: '/groups',
    TYPE: true,
  },
  REGION: {
    PATH: '/region',
    LABEL: '地域',
    KEY: '/region',
    TYPE: true,
  },
  VOLUNTEER: {
    PATH: '/volunteer',
    LABEL: 'ボランティア',
    KEY: '/volunteer',
    TYPE: true,
  },
  CONTACT: {
    PATH: '/contact',
    LABEL: 'コンタクト',
    KEY: '/contact',
    TYPE: true,
  },
  GROUPS_VOICE: {
    PATH: '/voice',
    LABEL: '体験談',
    KEY: '/groups-voice',
    TYPE: true,
  },
  CATEGORY: {
    PATH: '/category',
    LABEL: 'カテゴリー',
    KEY: '/category',
    TYPE: true,
  },
  VOICE: {
    PATH: '/voice',
    LABEL: '体験談',
    KEY: '/voice',
    TYPE: true,
  },
  GROUPS_BLOGS: {
    PATH: '/blogs',
    LABEL: 'ブログ',
    KEY: '/groups-blogs',
    TYPE: true,
  },
  CATEGORY_BLOGS: {
    PATH: '/categories-blogs',
    LABEL: 'カテゴリー',
    KEY: '/categories-blogs',
    TYPE: true,
  },
  TAG_BLOGS: {
    PATH: '/tag-blogs',
    LABEL: 'タグ',
    KEY: '/tag-blogs',
    TYPE: true,
  },
  BLOGS: {
    PATH: '/blogs',
    LABEL: 'ブログ',
    KEY: '/blogs',
    TYPE: true,
  },
  COMPARE: {
    PATH: '/compare',
    LABEL: '他の留学との比較',
    KEY: '/compare',
    TYPE: true,
  },
  REQUIREMENT: {
    PATH: '/requirement',
    LABEL: '参加条件と費用',
    KEY: '/requirement',
    TYPE: true,
  },
};
