import { createAsyncThunk } from '@reduxjs/toolkit';

import CATEGORIES_API from './categories.api';

export const getCategoriesAction = createAsyncThunk<any, Category.ParamsGetList>(
  'list_categories',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const data = await CATEGORIES_API.getCategoriesAPI(params);
      return data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getParentCategoriesAction = createAsyncThunk<any>(
  'list_parent_categories',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const data = await CATEGORIES_API.getParentCategoriesAPI();
      return data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const postCategoriesAction = createAsyncThunk<any, Category.ParamsPost>(
  'post_categories',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await CATEGORIES_API.postCategoriesAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);

export const putCategoriesAction = createAsyncThunk<any, Category.ParamsPutDelete>(
  'put_categories',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await CATEGORIES_API.updateCategoriesAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);

export const deleteCategoriesAction = createAsyncThunk<any, Category.ParamsPutDelete>(
  'delete_categories',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await CATEGORIES_API.deleteCategoriesAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);
