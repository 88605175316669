import { get } from 'lodash';
import Axios from 'src/configs/axios';

const COMPARE_API = {
  getRequirementAPI: async (params: Requirement.ParamsGetList) => {
    const query = new URLSearchParams(params as any)
    const response = await Axios.get(
      `/requirements?${query}`,
    );
    const data = get(response, 'data', null);
    return data;
  },

  postRequirementAPI: async (params: Requirement.ParamsPost) => {
    const response: any = await Axios.post('/requirements', params);
    return response.data;
  },

  updateRequirementAPI: async (params: Requirement.ParamsPutDelete) => {
    const response: any = await Axios.put(`/requirements/${params.id}`, params);
    return response.data;
  },

  deleteRequirementAPI: async (params: Requirement.ParamsPutDelete) => {
    const response: any = await Axios.delete(`/requirements/${params.id}`, params);
    return response.data;
  },

  getFeeAPI: async () => {
    const response = await Axios.get('/master-data/fee');
    const data = get(response, 'data', null);
    return data;
  },

  postFeeAPI: async (params: Requirement.ParamsPostFee) => {
    const response: any = await Axios.post('/master-data/fee', params);
    return response.status;
  },

};

export default COMPARE_API;
