import { createAsyncThunk } from '@reduxjs/toolkit';

import CONTACT_API from './contact.api';

export const getContactAction = createAsyncThunk<any, Contact.ParamsGetList>(
  'list_contact',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const data = await CONTACT_API.getContactAPI(params);
      return data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const deleteContactAction = createAsyncThunk<any, Contact.ParamsPutDelete>(
  'delete_contact',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await CONTACT_API.deleteContactAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);

export const getContactEmailAction = createAsyncThunk<any>(
  'list_contact_email',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const data = await CONTACT_API.getContactEmailAPI();
      return data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const postContactEmailAction = createAsyncThunk<any, Contact.ParamsContactEmail>(
  'post_contact_email',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await CONTACT_API.postContactEmailAPI(data);
      return response;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);
