import { createAsyncThunk } from '@reduxjs/toolkit';

import REQUIREMENT_API from './requirement.api';

export const getRequirementAction = createAsyncThunk<any, Requirement.ParamsGetList>(
  'list_requirement',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const data = await REQUIREMENT_API.getRequirementAPI(params);
      return data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const postRequirementAction = createAsyncThunk<any, Requirement.ParamsPost>(
  'post_requirement',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await REQUIREMENT_API.postRequirementAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);

export const putRequirementAction = createAsyncThunk<any, Requirement.ParamsPutDelete>(
  'put_requirement',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await REQUIREMENT_API.updateRequirementAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);

export const deleteRequirementAction = createAsyncThunk<any, Requirement.ParamsPutDelete>(
  'delete_requirement',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await REQUIREMENT_API.deleteRequirementAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);

export const getFeeAction = createAsyncThunk<any>(
  'list_fee',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const data = await REQUIREMENT_API.getFeeAPI();
      return data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const postFeeAction = createAsyncThunk<any, Requirement.ParamsPostFee>(
  'post_fee',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await REQUIREMENT_API.postFeeAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);
