import { createAsyncThunk } from '@reduxjs/toolkit';

import REGION_API from './region.api';

export const getRegionAction = createAsyncThunk<any>(
  'list_region',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const data = await REGION_API.getRegionAPI();
      return data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const postRegionAction = createAsyncThunk<any, Region.ParamsPost>(
  'post_region',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await REGION_API.postRegionAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);

export const putRegionAction = createAsyncThunk<any, Region.ParamsPutDelete>(
  'put_region',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await REGION_API.updateRegionAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);

export const deleteRegionAction = createAsyncThunk<any, Region.ParamsPutDelete>(
  'delete_region',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await REGION_API.deleteRegionAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);
