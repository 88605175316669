import { get } from 'lodash';
import Axios from 'src/configs/axios';

const VOLUNTEER_API = {
  getVolunteerAPI: async (params: Volunteer.ParamsGetList) => {
    const query = new URLSearchParams(params as any)
    const response = await Axios.get(
      `/volunteers?${query}`,
    );
    const data = get(response, 'data', null);
    return data;
  },

  postVolunteerAPI: async (params: Volunteer.ParamsPost) => {
    const response: any = await Axios.post('/volunteers', params);
    return response.data;
  },

  updateVolunteerAPI: async (params: Volunteer.ParamsPutDelete) => {
    const response: any = await Axios.put(`/volunteers/${params.id}`, params);
    return response.data;
  },

  deleteVolunteerAPI: async (params: Volunteer.ParamsPutDelete) => {
    const response: any = await Axios.delete(`/volunteers/${params.id}`, params);
    return response.data;
  },

};

export default VOLUNTEER_API;
