import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Typography, Dropdown } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import type { MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';

import actions from 'src/stores/screens/auth/auth.reducer';
import { useAppSelector, useAppDispatch } from 'src/stores';
import { ReactComponent as DashIconMenu } from 'src/assets/icons/menu/dashicons-menu.svg';
import { RevenueCardStyle, ProfileStyled, TextMenuStyled } from './RevenueCard.style';

interface PropsType {
  handleSidebar: () => void;
}

const RevenueCard: React.FC<PropsType> = ({ handleSidebar }) => {
  const { meInfo } = useAppSelector(state => state.auth);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { logOut } = actions;
  const { t } = useTranslation();

  const handleLogout = (): void => {
    localStorage.clear();
    dispatch(logOut());
    history.push('/login');
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Link to={'/profile'}>
          <TextMenuStyled>{t('global.profile')}</TextMenuStyled>
        </Link>
      ),
    },
    {
      key: '2',
      onClick: handleLogout,
      label: <TextMenuStyled>{t('global.logout')}</TextMenuStyled>,
    },
  ];
  return (
    <RevenueCardStyle>
      <DashIconMenu onClick={handleSidebar} className="dash-icon-menu" />
      <ProfileStyled>
        <Typography className="name">{meInfo?.name}</Typography>
        <Dropdown overlayStyle={{ width: '100px', fontWeight: 500 }} menu={{ items }} placement="bottom" arrow>
          <Avatar className="avatar" size="large" icon={<UserOutlined />} />
        </Dropdown>
      </ProfileStyled>
    </RevenueCardStyle>
  );
};

export default RevenueCard;
