import { createAsyncThunk } from '@reduxjs/toolkit';

import POSTS_API from './posts.api';

export const getListPostAction = createAsyncThunk<any, Posts.ParamsGetList>(
  'list_posts',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const data = await POSTS_API.getListPostAPI(params);
      return data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const postPostsAction = createAsyncThunk<any, any>(
  'post_posts',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await POSTS_API.postPostsAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);

export const putPostsAction = createAsyncThunk<any, Posts.ParamsPutDelete>(
  'put_posts',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await POSTS_API.updatePostsAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);

export const deletePostsAction = createAsyncThunk<any, Posts.ParamsPutDelete>(
  'delete_posts',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await POSTS_API.deletePostsAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);
