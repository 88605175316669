import { createSlice } from '@reduxjs/toolkit';
import {
  deleteRequirementAction,
  getFeeAction,
  getRequirementAction,
  postFeeAction,
  postRequirementAction,
  putRequirementAction,
} from './requirement.action';

const initialState: Requirement.RequirementState = {
  isLoading: false,
  error: null,
  listRequirement: {
    items: [],
    limit: 10,
    page: 1,
    totalCount: 0,
    totalPage: 0,
  },
  listFee: {},
};

const { actions, reducer } = createSlice({
  name: 'requirement_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getRequirementAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getRequirementAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listRequirement = {
          items: action.payload.records,
          limit: action.payload.pageSize,
          page: action.payload.page,
          totalCount: action.payload.totalRecords,
          totalPage: action.payload.totalPage,
        };
      })
      .addCase(getRequirementAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(postRequirementAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(postRequirementAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(postRequirementAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(putRequirementAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(putRequirementAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(putRequirementAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(deleteRequirementAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteRequirementAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteRequirementAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(getFeeAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getFeeAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listFee = action.payload.value;
      })
      .addCase(getFeeAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(postFeeAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(postFeeAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(postFeeAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export { reducer };
export default actions;
