import { createAsyncThunk } from '@reduxjs/toolkit';

import BLOGS_API from './blogs.api';

export const getBlogsAction = createAsyncThunk<any, Blogs.ParamsGetList>(
  'list_blogs',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const data = await BLOGS_API.getBlogsAPI(params);
      return data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const postBlogsAction = createAsyncThunk<any, Blogs.ParamsPost>(
  'post_blogs',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await BLOGS_API.postBlogsAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);

export const putBlogsAction = createAsyncThunk<any, Blogs.ParamsPutDelete>(
  'put_blogs',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await BLOGS_API.updateBlogsAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);

export const deleteBlogsAction = createAsyncThunk<any, Blogs.ParamsPutDelete>(
  'delete_blogs',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await BLOGS_API.deleteBlogsAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.name);
    }
  },
);
