import React, { useState } from 'react';
import { Layout } from 'antd';

import { PrivateLayoutStyle } from './PrivateLayout.style';
import RevenueCard from './revenue/RevenueCard';
import SideBarCard from './siderbar/SideBarCard';

const { Content } = Layout;

const PrivateLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [showSideBar, setIsShowSideBar] = useState(false);

  const handleSidebar = (): void => {
    setIsShowSideBar(!showSideBar);
  };

  return (
    <PrivateLayoutStyle>
      <Layout className="layout-body">
        <SideBarCard showSideBar={showSideBar} />
        <Content className="content">
          <RevenueCard handleSidebar={handleSidebar} />
          {children}
        </Content>
      </Layout>
    </PrivateLayoutStyle>
  );
};

export default PrivateLayout;
