import { get } from 'lodash';
import Axios from 'src/configs/axios';

const REGION_API = {
  getRegionAPI: async () => {
    const response = await Axios.get(
      '/regions',
    );
    const data = get(response, 'data', null);
    return data;
  },

  postRegionAPI: async (params: Region.ParamsPost) => {
    const response: any = await Axios.post('/regions', params);
    return response.data;
  },

  updateRegionAPI: async (params: Region.ParamsPutDelete) => {
    const response: any = await Axios.put(`/regions/${params.id}`, params);
    return response.data;
  },

  deleteRegionAPI: async (params: Region.ParamsPutDelete) => {
    const response: any = await Axios.delete(`/regions/${params.id}`, params);
    return response.data;
  },

};

export default REGION_API;
