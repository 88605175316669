import { createSlice } from '@reduxjs/toolkit';
import { deleteCategoriesAction, getCategoriesAction, getParentCategoriesAction, postCategoriesAction, putCategoriesAction } from './categories.action';

const initialState: Categories.CategoriesState = {
  isLoading: false,
  error: null,
  listCategories: {
    items: [],
    limit: 10,
    page: 1,
    totalCount: 0,
    totalPage: 0,
  },
  listParentCategories: [],
};

const { actions, reducer } = createSlice({
  name: 'categories_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getCategoriesAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCategoriesAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listCategories = {
          items: action.payload.records,
          limit: action.payload.pageSize,
          page: action.payload.page,
          totalCount: action.payload.totalRecords,
          totalPage: action.payload.totalPage,
        };
      })
      .addCase(getCategoriesAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getParentCategoriesAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getParentCategoriesAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listParentCategories = action.payload.records;
      })
      .addCase(getParentCategoriesAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(postCategoriesAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(postCategoriesAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(postCategoriesAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(putCategoriesAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(putCategoriesAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(putCategoriesAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(deleteCategoriesAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteCategoriesAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteCategoriesAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export { reducer };
export default actions;
