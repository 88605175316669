import { createSlice } from '@reduxjs/toolkit';
import { deleteCategoryPost, getCategoryPost, postCategoryPost, putCategoryPost } from './categoryPost.action';

const initialState: CategoryPost.CategoryPostState = {
  isLoading: false,
  error: null,
  listCategoryPost: [],
};

const { actions, reducer } = createSlice({
  name: 'categoryPost_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getCategoryPost.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCategoryPost.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listCategoryPost = action.payload.records;
      })
      .addCase(getCategoryPost.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(postCategoryPost.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(postCategoryPost.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(postCategoryPost.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(putCategoryPost.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(putCategoryPost.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(putCategoryPost.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(deleteCategoryPost.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteCategoryPost.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteCategoryPost.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export { reducer };
export default actions;
