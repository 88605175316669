import styled from 'styled-components';
import { Row, Typography } from 'antd';

export const RevenueCardStyle = styled(Row)`
  height: 64px;
  padding: 16px 24px;
  align-items: center;
  background-color: #ffffff;
  position: sticky;
  overflow: hidden;
  width: 100%;
  z-index: 100;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .dash-icon-menu {
    cursor: pointer;
  }
`;

export const ProfileStyled = styled.div`
  padding-right: 30px;
  display: flex;
  align-items: center;
  gap: 20px;

  .name {
    font-weight: 500;
  }

  .avatar {
    &:hover {
      cursor: pointer;
    }
  }
`;

export const TextMenuStyled = styled(Typography)`
  font-weight: 500;
  font-size: 12px;
`;
