import { createAsyncThunk } from '@reduxjs/toolkit';

import CATEGORY_API from './category.api';

export const getCategoryAction = createAsyncThunk<any, Category.ParamsGetList>(
  'list_category',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const data = await CATEGORY_API.getCategoryAPI(params);
      return data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const postCategoryAction = createAsyncThunk<any, Category.ParamsPost>(
  'post_category',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await CATEGORY_API.postCategoryAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.errorCode);
    }
  },
);

export const putCategoryAction = createAsyncThunk<any, Category.ParamsPutDelete>(
  'put_category',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await CATEGORY_API.updateCategoryAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.errorCode);
    }
  },
);

export const deleteCategoryAction = createAsyncThunk<any, Category.ParamsPutDelete>(
  'delete_category',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const user = await CATEGORY_API.deleteCategoryAPI(data);
      return user;
    } catch (err: any) {
      rejectWithValue(err?.message || err?.errorCode);
    }
  },
);
