import React from 'react';
import { Image, Menu } from 'antd';

import useSideBarHook from './useSideBarHook';
import { SideBarCardStyled } from './SideBarCard.style';
import { LOGO } from 'src/constants';

interface PropsType {
  showSideBar: boolean;
}
const SideBarCard: React.FC<PropsType> = ({ showSideBar }) => {
  const { menuDisplay, menuSelected, getSelectedMenu, getDisplayMenu } = useSideBarHook();

  return (
    <SideBarCardStyled
      width={262}
      className="layout-sider"
      collapsedWidth={0}
      collapsible
      trigger={null}
      collapsed={showSideBar}
    >
      <Image className='logo-header' src={LOGO} preview={false} alt='logo' />

      <Menu
        className="layout-menu"
        mode="inline"
        defaultOpenKeys={getSelectedMenu(menuSelected).map(item => item.key)}
        selectedKeys={getSelectedMenu(menuSelected).map(item => item.key)}
        items={getDisplayMenu(menuDisplay)}
      />
    </SideBarCardStyled>
  );
};

export default SideBarCard;
